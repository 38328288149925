/*
 * @Author: 
 * @Descripttion: ...
 * @Date: 2021-03-22 13:49:26
 */
import Vue from 'vue'
import App from './app.vue'
import router from "../../router/login.js"
import YawlihUI2 from 'yawlih-ui2'
import 'yawlih-ui2/package/styles/common/oldYawlihTheme.css';

// 国际化处理
import VueI18n from 'vue-i18n'
Vue.use(VueI18n);
import {fn_Geti18nConfig}  from '../../../public/language/index';
import * as Login from '../../api/login'

fn_setLang();

async function fn_setLang(){
  let params = {
    Code:["Common.loginset.defultLang"]
  }
  let result = await Login.fn_GetConfigs(params);
  var functionConfig = result.data.data.functionConfig;
  functionConfig.forEach((item) => {
      switch (item.code) {
        case 'Common.loginset.defultLang':
          localStorage.setItem('lang', item.value || 'zh_CN')
          break
      }
  })
  if(!localStorage.getItem('lang')){
    localStorage.setItem('lang', 'zh_CN')
  };
  let i18n = fn_Geti18nConfig(VueI18n);
  Vue.use(YawlihUI2, {
    i18n: (key, value) => i18n.t(key, value),
  })
  new Vue({
    render: h => h(App),
    router,
    i18n
  }).$mount('#app')
  Vue.config.productionTip = false;

}


